export default {
  // 首页
  aboutus: "关于我们",
  contactus:"联系我们",
  downpdf:"下载PDF",
  getData:"获取资料",
  news: "新闻资讯",
  address:"北京朝阳区望京街道望京SOHO T1C座1012",
  messageBord:"留言板",
  username:"姓名",
  phone:"电话",
  email:"邮箱",
  message:"留言",
  submit:"提交",
  pleaseLeave:"请留下您的联系方式",
  pleaseCorrect:"请提供正确的邮箱地址，便于我们与您联系",
  submitSuccess:"提交成功",
  bottom:"© 2024 FUTURE CITIZEN BUREAU 保留所有权利",
  newsPrev:"上一篇",
  newsNext:"下一篇",
  newsReturnList:"返回列表",
  LearnMore:"查看更多",
  showDetail:"查看详情",
  irelandProject:"爱尔兰项目",
  vanuatuProject:"瓦努阿图项目",
  index_banner_ireland1:"爱尔兰联合投资移民项目旨在吸引全球投资者，通过对爱尔兰企业进行资本注入并以高管身份参与企业的战略运营和管理。",
  index_banner_ireland2:"法案依据：DETE（爱尔兰劳工部）Critical Skills Employment Permits 法案 和 Irish Immigration（爱尔兰移民局）Stamp 1 法案相结合，使投资申请人获得基于高级企业管理人的居留签证，两年后可顺利转换爱尔兰长居身份。",
  index_banner_vanuatu1:"瓦努阿图入籍移民，根据投资/捐赠贡献的移民计划法案，投资申请人通过向瓦努阿图政府指定批准的项目进行投资或直接捐赠贡献，即有资格根据《公民法第112 章》第 13 条提交公民入籍申请，通过申请后获得瓦努阿图公民身份。",
  index_banner_vanuatu2:"瓦努阿图共和国，根据《公民法第 112 章》第 13 条，先后立法通过：《2016年第 220 号国籍条例（捐献计划）法案》，即Development Support Program（DSP）和《2023 年 No.8 公民身份资本投资移民计划法案》《2023 年 No.58 公民身份资本投资移民计划法案》，即Capital Investment Immigration Plan（CIIP）。",
  service_content:"服务内容",
  cooperateAdv:"合作优势",
  bahamas:"巴哈马",
  slovenia:"斯洛文尼亚",
  ireland:"爱尔兰",
  vanuatu:"瓦努阿图",
  projectPlan:"项目计划",
  projectOverview:"项目概况",
  applicant:"申请条件",
  moreNews:"更多新闻动态",
  flag:"国旗",
  emblem:"国徽",

};
