<template>
  <div>
    <Navbar></Navbar>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/layout/components/Footer.vue";
import Navbar from "@/layout/components/Navbar.vue";
export default {
  name: "Layout",
  data() {
    return {
      // isMobile: /Mobi|Android/i.test(navigator.userAgent),
    };
  },
  components: {
    Footer,
    Navbar,
  },
};
</script>
<style lang="scss"></style>
