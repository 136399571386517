import axios from "axios";

const service = axios.create({
    //基础路径URL配置
    baseURL: "https://api.futurecitizen.group",
    //五秒未响应提示
    timeout: 5000,
});

export const FormSubmit = (FormData) => {
    try {
        const response = service.post('/api/eng/submit', FormData);
        return response;
    } catch (error) {
        // 错误处理
        console.error('An error occurred while fetching the data:', error);
        throw error;
    }
}
export const GetNav = () => {
    try {
        const response = service.post('/api/eng/GetNav', {
            lang: localStorage.getItem('language'),
        });
        return response;
    } catch (error) {
        // 错误处理
        console.error('An error occurred while fetching the data:', error);
        throw error;
    }
}
export const GetPageIntro = (page) => {
    try {
        const response = service.post('/api/eng/GetPageIntro', {
            lang: localStorage.getItem('language'),
            page: page
        });
        return response;
    } catch (error) {
        // 错误处理
        console.error('An error occurred while fetching the data:', error);
        throw error;
    }
}
export const GetNewsList = (page, type = 'trends', limit = 3) => {
    try {
        const response = service.post('/api/eng/GetNewsList', {
            lang: localStorage.getItem('language'),
            page: page,
            type: type,
            limit: limit
        });
        return response;
    } catch (error) {
        // 错误处理
        console.error('An error occurred while fetching the data:', error);
        throw error;
    }
}
export const GetNewsTypes = () => {
    try {
        const response = service.post('/api/eng/GetNewsTypes', {
            lang: localStorage.getItem('language'),
        });
        return response;
    } catch (error) {
        // 错误处理
        console.error('An error occurred while fetching the data:', error);
        throw error;
    }
}
export const GetNewsDetail = (id) =>{
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    try {
        const response = service.post('/api/eng/GetNewsDetail', {
            lang: localStorage.getItem('language'),
            id: id,
            isMobile: isMobile,
        });
        return response;
    } catch (error) {
        // 错误处理
        console.error('An error occurred while fetching the data:', error);
        throw error;
    }
}