<!-- Overlay.vue -->
<template>
  <div class="overlay">
    <svg viewBox="0 0 100 140" class="icon" xmlns="http://www.w3.org/2000/svg">
      <!-- 空心圆 -->
      <circle
        cx="50"
        cy="50"
        r="40"
        stroke="#ba9153"
        stroke-width="5"
        fill="none"
        class="circle"
      />
      <!-- 勾图标 -->
      <path
        d="M30,50 L45,65 L70,40"
        stroke="#ba9153"
        stroke-width="5"
        fill="none"
        class="checkmark"
      />
      <!-- 文字 -->
      <text
        x="50"
        y="135"
        fill="white"
        font-size="30"
        text-anchor="middle"
        font-family="SimSun, serif"
        class="text"
      >
        {{ $t('submitSuccess') }}
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "Overlay",
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  padding-top: 150px;
  /* align-items: center; */
  z-index: 999999;
}

.icon {
  width: 400px;
  height: 155px; /* 增加高度以容纳文字 */
  margin-top: 200px;
}

.circle,
.checkmark {
  stroke-dasharray: 251; /* 圆周长度 */
  stroke-dashoffset: 251; /* 初始偏移量 */
  animation: draw 1.5s forwards;
}

.checkmark {
  stroke-dasharray: 80; /* 勾路径长度 */
  stroke-dashoffset: 80; /* 初始偏移量 */
}

@keyframes draw {
  to {
    stroke-dashoffset: 0; /* 动画结束时，偏移量为0 */
  }
}

.text {
  animation: fadeIn 1.5s forwards; /* 文字渐显动画 */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
