import Vue from "vue";
import VueRouter from "vue-router";

import Layout from "@/layout/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: Layout,
    children: [
      {
        // 首页
        path: "",
        name: "home",
        component: () => import("@/views/index.vue"),
        meta: { title: "亿恩基" },
      },
      {
        // 服务项目
        path: "project",
        name: "project",
        component: () => import("@/views/project.vue"),
        meta: { title: "亿恩基" },
      },
      {
        // 新闻资讯
        path: "news",
        name: "news",
        component: () => import("@/views/news.vue"),
        meta: { title: "亿恩基" },
      },
      {
        // 新闻资讯详情
        path: "newsdetail",
        name: "newsDetail",
        component: () => import("@/views/newsDetail.vue"),
        meta: { title: "亿恩基" },
      },
      {
        // 关于我们
        path: "aboutus",
        name: "aboutus",
        component: () => import("@/views/aboutus.vue"),
        meta: { title: "亿恩基" },
      },
      {
        // 爱尔兰详情页
        path: "ireland",
        name: "ireland",
        component: () => import("@/views/projectDetail/ireland.vue"),
        meta: { title: "亿恩基" },
      },
      {
        // 瓦努阿图详情页
        path: "vanuatu",
        name: "vanuatu",
        component: () => import("@/views/projectDetail/vanuatu.vue"),
        meta: { title: "亿恩基" },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  document.title = to.meta.title;
  window.scrollTo(0, 0);
});

export default router;
