<template>
  <div>
    <div v-if="visible" class="modal-overlay">
      <div class="modal-content">
        <div class="div_img" @click="close"></div>
        <div class="left">
          <div class="leftbox">
            <div class="title">{{ $t("contactus") }}</div>
            <form @submit.prevent="submitForm">
              <div class="input-container">
                <input
                  type="text"
                  id="input"
                  v-model="form.username"
                  :placeholder="`${$t('username')}`"
                />
                <div class="underline"></div>
              </div>
              <div class="input-container">
                <input
                  type="text"
                  id="input"
                  v-model="form.phone"
                  :placeholder="`${$t('phone')}`"
                />
                <div class="underline"></div>
              </div>
              <div class="input-container">
                <input
                  type="text"
                  id="input"
                  v-model="form.email"
                  v-bind:class="{ 'is-error': !emailValid }"
                  :placeholder="`${$t('email')}*`"
                />
                <div class="underline"></div>
              </div>
              <div class="input-container">
                <input
                  type="text"
                  id="input"
                  v-model="form.message"
                  :placeholder="`${$t('message')}`"
                />
                <div class="underline"></div>
              </div>
              <div class="remind">*{{ $t("pleaseCorrect") }}</div>
              <button class="submit" type="submit">{{ $t("submit") }}</button>
            </form>
          </div>
        </div>
        <div class="right"></div>
      </div>
    </div>
     <Overlay v-if="showOverlay" />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import {FormSubmit} from "@/api/api";
// import { FormSubmit } from "@/api/api";
export default {
  name: "Dialog",
  components: {
    Overlay,
  },
  data() {
    return {
      selectedOption: "",
      form: {},
      showOverlay: false,
      emailValid: true,
      emailRegex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    showOverlay(newValue) {
      if (newValue) {
        // 三秒后隐藏蒙层
        setTimeout(() => {
          this.showOverlay = false;
        }, 3000);
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    submitForm() {
      this.form.active = this.$route.name;
      if (this.form.email == "" || this.form.email == undefined) {
        this.emailValid = false;
        return;
      } else {
        console.log(this.emailRegex.test(this.form.email));
        if (!this.emailRegex.test(this.form.email)) {
          this.emailValid = false;
          return;
        }
        this.emailValid = true;
      }
      FormSubmit(this.form).then((res) => {
        if (res.data.code == 1) {
          this.form = {};
          this.$emit("update:visible", false);
          this.showOverlay = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  width: 46.35%;
  height: 56.6%;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  text-align: center;
  position: relative;
  display: flex;
  .div_img {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 40px;
    top: 40px;
    background: url(@/assets/close.png) no-repeat;
    cursor: pointer;
  }
  .right {
    height: 100%;
    width: 55%;
    background: url(@/assets/map.png) no-repeat;
    // background: palevioletred;
    background-size: cover;
    background-position: right;
    // position: relative;
    // z-index: ;
  }
  .left {
    height: 100%;
    width: 45%;
    // padding: 15.19% 4.5% 14.47% 9%;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      font-weight: 400;
      font-family: "Source Han Sans SC", "Microsoft YaHei", "微软雅黑",
        "sans-serif";
      font-size: 20px;
      color: #1e2344;
      line-height: 20px;
      padding-bottom: 10px;
    }
  }
}
.leftbox {
  padding-left: 8%;
}
/* From Uiverse.io by Satwinder04 */
.input-container {
  position: relative;
  margin: 15px auto;
  width: 100%;
}
.input-container input[type="text"] {
  font-size: 16px;
  width: 100%;
  border: none;
  color: #000;
  border-bottom: 1px solid #e4e4e4;
  padding: 10px 0 15px 0;
  background-color: transparent;
  outline: none;
  display: block;
}
.input-container .is-error {
  border-bottom: 1px solid #f00 !important;
}

.input-container .underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #ba9153;
  transform: scaleX(0);
  transition: all 0.3s ease;
}

.input-container input[type="text"]:focus ~ .underline {
  transform: scaleX(1);
}
.remind {
  padding-top: 5%;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  line-height: 12px;
}
.submit {
  margin-top: 10px;
  // width: 280px;
  width: 100%;
  height: 34px;
  background: #ba9153;
  border-radius: 2px 2px 2px 2px;
  line-height: 34px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  border: none;
}
</style>
